import { NgModule } from '@angular/core';
import { provideRouter } from '@angular/router';
import { cpNativeAppRoutes } from './cp-native-app.routes';
import { TranslateModule } from '@ngx-translate/core';
import { CpNativeAppViewComponent } from './views/cp-app-native-app-view/cp-native-app-view.component';
import { UiModule } from '@dm-workspace/ui';

@NgModule({
  imports: [TranslateModule, UiModule],
  declarations: [CpNativeAppViewComponent],
  providers: [provideRouter(cpNativeAppRoutes)],
})
export class CpNativeAppModule {}
