import { environmentDefault } from './environment.default';
import { IEnvironment } from './interfaces/environment.interface';

export const environment: IEnvironment = {
  ...environmentDefault,
  apiUrl: 'https://dmarin-api-dev.futuremind.dev',
  staticUrl: 'https://dmarin-mms-dev.futuremind.dev/static/',
  production: false,
  stage: false,
  adyen: {
    environment: 'test',
  },
  resetPasswordUrl: 'https://dmarin-customer-dev.futuremind.dev/auth/password-lost',
};
