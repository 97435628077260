import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CpAppComponent } from './cp-app.component';
import { cpAppRoutes } from './cp-app.routes';
import { APP_TOKEN, AppToken, API_PREFIX, ApiPrefix, ENV } from '@dm-workspace/types';
import { TranslateCoreModule } from '@dm-workspace/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NotificationModule } from '@dm-workspace/notification';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { isAnimationsDisabled } from '@dm-workspace/utils';
import { environment } from '@dm-workspace/environments';
import { SentryCoreModule } from '@dm-workspace/sentry-core';
import { ServiceWorkerCoreModule } from '@dm-workspace/service-worker-core';

@NgModule({
  declarations: [CpAppComponent],
  providers: [
    {
      provide: ENV,
      useValue: environment,
    },
    {
      provide: APP_TOKEN,
      useValue: AppToken.customerPanel,
    },
    {
      provide: API_PREFIX,
      useValue: ApiPrefix.customerPanel,
    },
  ],
  imports: [
    BrowserAnimationsModule.withConfig({ disableAnimations: isAnimationsDisabled }),
    HttpClientModule,
    CommonModule,
    SentryCoreModule,
    NotificationModule,
    TranslateCoreModule,
    BrowserModule,
    RouterModule.forRoot(cpAppRoutes),
    ServiceWorkerCoreModule,
  ],
  bootstrap: [CpAppComponent],
})
export class CpAppModule {}
