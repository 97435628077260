import { Routes } from '@angular/router';
import { CpNativeAppModule } from '@dm-workspace/customer-portal/native-app';

export const cpAppRoutes: Routes = [
  {
    path: '',
    loadChildren: () => CpNativeAppModule,
  },
  {
    path: 'auth',
    loadChildren: () => import('@dm-workspace/customer-portal/cp-auth').then((m) => m.CpAuthModule),
  },
  {
    path: '**',
    redirectTo: '/',
  },
];
