<section class="hero">
  <img class="line-decoration" src="/assets/line-decoration-left.svg">

  <div class="wrap">
    <div class="row gy-5">
      <div class="col-12 col-md-7 col-lg-6">
        <h1 class="title">
          {{ "CP_NATIVE_APP_VIEW_HERO_.TITLE" | translate }}
        </h1>
        <p class="mb-space typo-header-l typo-header-md-xl">
          {{ "CP_NATIVE_APP_VIEW_HERO_.SUBTITLE" | translate }}
        </p>
        <div class="typo-body-l color-alabaster" [innerHTML]="'CP_NATIVE_APP_VIEW_HERO_.BODY' | translate">
        </div>
      </div>

      <div class="col-md-4 col-lg-6">
        <div class="d-md-none">
          <div class="row text-center">
            <dm-ui-native-app-badge-link class="col-6" store="Apple" />
            <dm-ui-native-app-badge-link class="col-6" store="Google" />
          </div>
        </div>

        <picture class="native-app-preview d-none d-md-block">
          <source srcset="/assets/customer-portal-native-app-preview@2x.png 2x,
                /assets/customer-portal-native-app-preview.png 1x" />
          <img src="/assets/customer-portal-native-app-preview.png">
        </picture>
      </div>
    </div>
  </div>
</section>

<div class="body">
  <div class="wrap">
    <div class="row gx-5">
      <div class="col-12 col-md-6">
        <section class="mb-space-m">
          <h2 class="typo-header-l typo-header-md-xl mb-space">
            {{ "WHATS_NEW" | translate }}?
          </h2>
          <div class="typo-body-l color-iron-light" [innerHTML]="'CP_NATIVE_APP_VIEW_WHATS_NEW' | translate">
          </div>
        </section>

        <section>
          <h2 class="typo-header-l typo-header-md-xl mb-space">
            {{ "SEE_YOU_IN_THE_APP" | translate }}!
          </h2>
          <div class="typo-body-l color-iron-light" [innerHTML]="'CP_NATIVE_APP_VIEW_SEE_YOU_IN_THE_APP' | translate">
          </div>
        </section>
      </div>

      <div class="d-none d-md-block col-md-6 col-lg-5 offset-lg-1">
        <section>
          <h2 class="typo-header-l typo-header-md-xl mb-space text-center">
            {{ "DOWNLOAD_OUR_D-MARIN_APP" | translate }}
          </h2>
          <div class="text-center mb-space-m">
            <img class="native-app-qrcode" src="/assets/dmarin-app-qrcode.svg">
          </div>
          <div class="row text-center">
            <dm-ui-native-app-badge-link class="col-6" store="Apple" />
            <dm-ui-native-app-badge-link class="col-6" store="Google" />
          </div>
        </section>
      </div>
    </div>
  </div>
</div>
