import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ApiPrefix } from '@dm-workspace/types';
import { CpAppModule } from './cp-app/cp-app.module';
import { environment } from '@dm-workspace/environments';
import { DmSentry } from '@dm-workspace/sentry-core';

DmSentry.init({
  apiPrefix: ApiPrefix.customerPanel,
  env: environment,
});
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(CpAppModule)
  .catch((err) => console.error(err));
